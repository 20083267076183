/* @font-face { */
/*   font-family: 'Darker Grotesque'; */
/*   font-style: normal; */
/*   font-weight: 400; */
/*   src: local('Darker Grotesque Regular'), local('DarkerGrotesque-Regular'), */
/*     url(./assets/fonts/DarkerGrotesque-Regular.ttf) format('truetype'); */
/* } */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
}

/* Passages  */
.bible-content {
  text-align: justify;
  width: 50%;
  margin: 6rem auto auto auto;
}

.p {
  margin: 0;
  font-size: 14px;
}

p:last-of-type {
  margin-bottom: 1rem;
}

span[data-number] {
  font-weight: bold;
  padding: 0 10px;
}


.css-fgtxlb {
  font-size: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #ff6600;
}
